<template>
  <div>
    <a-button @click="printThis()">打印</a-button>
  </div>
</template>

<script>
import {hs_print} from '../../../utils/hs_print.js';

export default {
  name: "dayin",
  methods: {
    printThis() {
      hs_print([
        174,
        175,
        176,
      ])
      // let url = '//api.tengbocn.com/upload/pdf/1639476480974.pdf'
      // printJS({printable: url, type: 'pdf'});// 解决跨域
    }
  }
}
</script>

<style scoped>

</style>
